.dashboard_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .tool_box {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
    padding: 1vw 7vw 0 7vw;
    align-items: center;

    .category_wrapper {
      display: flex;
      overflow: hidden;
      width: fit-content;

      span {
        padding: 1vw 1.5vw;
        font-size: 1vw;
        min-width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.4vw;
        cursor: pointer;

        img {
          width: 1.2vw;
        }
      }

      .selected_category {
        border-bottom: 0.2vw solid var(--blue);
      }
    }

    .search_bar {
      position: relative;
      margin-left: auto;
      display: flex;
      align-items: center;
      border-radius: 1000px;
      border: 1px solid var(--blue);
      width: 2.5vw;
      height: 2.5vw;
      transition: width 0.4s ease;

      .search_input {
        width: 0;
        height: 100%;
        opacity: 0;
        border: none;
        outline: none;
        transition:
          width 0.4s ease,
          border-radius 0.4s ease;
        font-size: 0.8vw;
        background: transparent;
      }

      .search_icon {
        cursor: pointer;
        color: #b0b0b0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1vw;
        height: 1vw;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &.active,
      &:focus {
        width: 20vw;
        padding: 0.5vw;
        .search_input {
          opacity: 1;
          width: -webkit-fill-available;
          padding-left: 0.5vw;
        }

        .search_icon {
          transform: none;
          position: unset;
        }
      }
    }
  }
  .dashboard_container {
    padding: 1vw 7vw;
    flex: 1;
    overflow: auto;

    .game_list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(19vw, 19vw));
      gap: calc(10vw / 3);
      height: fit-content;

      .game_item {
        text-align: center;
        display: flex;
        flex-direction: column;
        transition: box-shadow 0.3s;
        cursor: pointer;
        position: relative;

        .image_container {
          position: relative;
          overflow: hidden;
          line-height: 0;
          border-radius: 1vw;
          box-shadow: 0 10px 30px -15px #000;

          .game_image {
            max-width: 100%;
            width: 100%;
            filter: brightness(1);
            transition: filter 0.3s ease-in-out;
            aspect-ratio: 326/231;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }

          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: opacity 0.3s ease;

            .config_button {
              color: white;
              border: 1px solid white;
              padding: 0.3vw 0.7vw;
              font-size: 1vw;
              transition: all 1.3s ease;

              &:hover {
                background: linear-gradient(to bottom, #3ea5e8, #1857a6);
                border: none;
              }
            }
          }
        }

        .game_name {
          width: 100%;
          text-align: center;
          padding: 10px;
          font-size: 1vw;
        }

        &:hover {
          .overlay {
            opacity: 1;
          }
        }
      }

      .selected {
        .image_container {
          .game_image {
            filter: brightness(0.7);
          }
        }
      }
    }

    .table_nodata {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 1vw;
      height: 100%;
      padding-bottom: 5vw;

      img {
        width: 20vw;
      }

      span {
        color: #c4c4c4;
        font-size: 0.8vw;
      }
    }
  }

  .config_game_container {
    z-index: 99;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 3vw 0;
    height: 100%;

    .left {
      display: flex;
      flex-direction: column;
      padding: 1vw;
      border-top-left-radius: 1vw;
      border-bottom-left-radius: 1vw;
      box-shadow: 0 0 20px -8px #000;
      height: calc(100vh - 9.6875vw);
      aspect-ratio: 618/749;
      overflow: hidden;

      h2 {
        font-size: 1.5vw;
        text-transform: uppercase;
        font-weight: 500;
      }
      .config_description {
        font-size: 1vw;
      }

      .config_tab {
        display: flex;
        flex-direction: column;
        min-height: 100px;

        .config_scroll {
          overflow-y: auto;
          .config_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            gap: 5px;

            label {
              width: 100%;
              color: #333333;
              font-size: 1vw;
            }

            input,
            select {
              width: 100%;
              padding: 0.5vw;
              border-radius: 0.25vw;
              border: 1px solid #1857a6;
              background-color: #fff;
            }

            select {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              padding-right: 1vw;
              background-image: url("../../assets/icons/arrow-down.svg");
              background-repeat: no-repeat;
              background-position: right 0.5vw center;
              background-size: 1vw;
              font-size: 1vw;
            }

            select option {
              font-size: 14px; /* Tùy chỉnh kích thước font cho option */
              padding: 10px;
            }

            /* Ẩn spinner (nút tăng giảm) cho Chrome, Safari và Edge */
            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Ẩn spinner cho Firefox */
            input[type="number"] {
              -moz-appearance: textfield;
            }

            .input_wrapper {
              position: relative;
              display: inline-block;
              width: 100%;

              input {
                padding-right: 40px;
              }

              .unit {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
                font-size: 0.8vw;
                color: #666;
              }
            }
          }

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }

    .right {
      width: fit-content;
      height: calc(100vh - 9.6875vw);
      aspect-ratio: 618/749;
      position: relative;
      line-height: 0;
      border-top-right-radius: 1vw;
      border-bottom-right-radius: 1vw;
      box-shadow: 0 0 20px -8px #000;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 21%,
          rgba(0, 0, 0, 0.9) 86%,
          rgba(0, 0, 0, 0.9) 100%
        );
        pointer-events: none;
        z-index: 1;
        border-top-right-radius: 1vw;
        border-bottom-right-radius: 1vw;
        box-shadow: 0 0 20px -8px #000;
      }

      img {
        height: 100%;
        border-top-right-radius: 1vw;
        border-bottom-right-radius: 1vw;
      }

      .float {
        position: absolute;
        bottom: 2vw;
        left: 50%;
        translate: -50% 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        gap: 0.5vw;
        width: 100%;
        align-items: center;

        .gameName {
          color: white;
          line-height: normal;
          font-size: 1.5vw;
          font-weight: 900;
        }

        .play_button {
          color: white;
          padding: 0.5vw 3vw;
          text-transform: uppercase;
          font-weight: 600;
          transition: all 0.3s ease;

          &:hover {
            background: var(--orange-primary);
          }
        }
      }
    }

    .close_btn {
      position: absolute;
      top: 2vw;
      right: 5vw;
      width: 3vw;
      height: 3vw;
      cursor: pointer;
      display: block;
      background: #d9d9d921;
      border: none;
      border: 1px solid #ccc;
      font-size: 1.5vw;
      font-weight: 300;
    }
  }
}
