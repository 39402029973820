.customSwitchUserPopup {
  width: 50vw;
  height: 50vh;
  border-radius: 20px;
  background-color: #fff;
  text-align: center;
  z-index: 999;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;

  .switch_user_popup_content {
    padding: 0;
    display: flex;
    flex: 1;

    .side {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;

      .title {
        font-weight: 900;
        font-size: 30px;
        margin-bottom: 1vw;
      }

      p {
        color: #6f6c90;
        margin: 1vw 0;
        font-size: 0.9vw;
        margin-block: 0;
      }

      .input_box {
        width: 70%;
        display: flex;
        flex-direction: column;
        margin: 25px 0;
        gap: 10px;

        p {
          text-align: left;
          margin: 0;
        }
        input {
          font-size: medium;
          padding: 15px 16px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          border-radius: 12px;
          border: 1px solid #ccc;
          font-family: "noto_sans_jp", -apple-system, BlinkMacSystemFont,
            "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
            "Droid Sans", "Helvetica Neue", sans-serif;
          text-transform: uppercase;

          &::placeholder {
            text-transform: none;
          }
        }
      }

      .switching_btn {
        width: fit-content;
        align-items: center;
        border: none;
        border-radius: 1000px;
        color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 10px 40px;
        transition: all 0.25s;
        margin-top: 20px;
        cursor: pointer;
        font-size: 18px;
      }
    }
  }

  .close_btn {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: 1vw;
    top: 1vw;
    background: unset;
    border: none;
    font-size: 30px;
  }
}

.customUserInformationPopup {
  width: 50vw;
  height: 70vh;
  border-radius: 26px;
  background-color: #fff;
  text-align: center;
  z-index: 999;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  overflow: hidden;

  .user_infor_popup_content {
    padding: 0;
    display: flex;
    flex: 1;

    .left_side {
      background-color: #f9fdff;
      flex: 4;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .side {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 6;
      padding: 4vw;

      .title {
        font-weight: 600;
        font-size: 30px;
        text-align: left;
      }

      p {
        color: #6f6c90;
        margin: 1vw 0;
        font-size: 0.9vw;
      }

      .input_row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 0.8vw;
        width: 100%;
        box-shadow: 0px 2px 6px 0px #13124212;
        border-radius: 0.6vw;
        border: 1px solid #eff0f6;

        input,
        select {
          font-size: 20px;
          padding: 15px;
          border: none;
          border-radius: 0.6vw;
        }

        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background-color: #fff;
          background-image: url("../../assets/icons/arrow-down.svg");
          background-repeat: no-repeat;
          background-position: right 10px center;
          background-size: 18px;
        }
      }

      .submit_btn {
        margin-top: 1.2vw;
        font-size: 1.2vw;
        padding: 0.6vw 3.2vw;
      }
    }
  }

  .close_btn {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: 30px;
    top: 30px;
    background: unset;
    border: none;
    font-size: 30px;
  }
}

@media (min-width: 2560px) {
  .customSwitchUserPopup {
    border-radius: 30px;

    .switch_user_popup_content {
      .side {
        gap: 20px;

        .title {
          font-size: 45px;
        }

        .input_box {
          width: 80%;
          display: flex;
          flex-direction: column;
          margin: 25px 0;
          gap: 10px;
          p {
            text-align: left;
            margin: 0;
          }
          input {
            padding: 25px 20px;
            border-radius: 16px;
          }

          p,
          input {
            font-size: 25px;
          }
        }

        .switching_btn {
          padding: 20px 80px;
          font-size: 30px;
        }
      }
    }

    .close_btn {
      font-size: 50px;
      right: 2vw;
      top: 2vw;
    }
  }

  .customUserInformationPopup {
    border-radius: 30px;

    .user_infor_popup_content {
      .left_side {
        width: 40%;
        flex: none;
      }
      .side {
        padding: 4vw;
        width: 60%;
        flex: none;
        .title {
          font-size: 40px;
        }

        p {
          margin: 1vw 0;
          font-size: 1vw;
        }

        .input_row {
          input,
          select {
            padding: 25px;
            border-radius: 10px;
            font-size: 26px;
          }
        }
      }
    }

    .close_btn {
      right: 40px;
      top: 40px;
      font-size: 40px;
    }
  }
}

@media (min-width: 3200px) {
  .customSwitchUserPopup {
    border-radius: 50px;

    .switch_user_popup_content {
      .side {
        gap: 70px;
        .title {
          font-size: 74px;
        }

        .input_box {
          width: 85%;
          display: flex;
          flex-direction: column;
          margin: 25px 0;
          gap: 10px;
          p {
            text-align: left;
            margin: 0;
          }
          input {
            padding: 35px 20px;
            border-radius: 26px;
          }

          p,
          input {
            font-size: 35px;
          }
        }

        .switching_btn {
          padding: 25px 110px;
          font-size: 50px;
        }
      }
    }

    .close_btn {
      font-size: 80px;
    }
  }

  .customUserInformationPopup {
    border-radius: 40px;

    .user_infor_popup_content {
      .left_side {
        width: 40%;
        flex: none;

        img {
          width: 90%;
        }
      }
      .side {
        .title {
          font-size: 60px;
        }

        p {
          margin: 1vw 0;
          font-size: 1vw;
        }

        .input_row {
          border-radius: 16px;
          margin-bottom: 1vw;
          input,
          select {
            flex: 2;
            padding: 40px;
            border-radius: 16px;
            font-size: 45px;
          }
        }
      }
    }

    .close_btn {
      right: 80px;
      top: 80px;
      font-size: 60px;
    }
  }
}
