body {
  margin: 0;
  font-family: "noto_sans_jp", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  /* background-image: url("/src/assets/img/background.webp"); */
  background-size: cover;
  background-position: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  font-family: "noto_sans_jp", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  padding: 0;
  background-color: unset;
  border: none;
  cursor: pointer;
}

.no_scrollbar {
  /* IE và Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    /* Chrome, Safari, Opera */
    display: none;
  }
}

.japanese-font {
  font-family: "noto_sans_jp", sans-serif;
  /* font-family: "ms_gothic", sans-serif; */
}

.japanese-font button,
.japanese-font input {
  font-family: "noto_sans_jp", sans-serif;
  /* font-family: "ms_gothic", sans-serif; */
}

input:focus-visible {
  outline: none;
}

select:focus-visible {
  outline: none;
}

* {
  box-sizing: border-box;
}

:root {
  --primary-color: #1992cd;
  --blue: #007bff;
  --blue-secondary: #1992cd;
  --orange-primary: #f3b357;
  --border: #e1e3e9;
  --text-color-blue: #399adf;
  --scrollbar-thumb: #dadce0;
  --blue-tertiary: #1992cd1a;
  --bgc-primary: #ddeff8;
  --text-color-primary: rgba(26, 25, 25, 0.7);
  --black-text-secondary: #1a1919;
  --red-primary: #ff0401;
  --red-secondary: #ff04011a;
  --gray-primary: #f7f7f7;
  --gray-secondary: #e1e1e1;
  --green-primary: #48cd3d;
  --green-secondary: #ebfeea;
  --warning-primary: #ff8a01;
  --warning-secondary: #ff8a011a;
  --border-radius-button: 12px;
  --padding-large: 40px;
  --padding-medium: 16px;
  --padding-small: 12px;

  --font-large: 20px;
  --font-medium: 18px;
  --font-small: 16px;
}

@media (min-width: 2560px) {
  :root {
    --padding-large: 50px;
    --padding-medium: 24px;
    --padding-small: 20px;

    --font-large: 36px;
    --font-medium: 30px;
    --font-small: 24px;
  }
}

/* Điều chỉnh cho màn hình 4K (3840x2160) */
@media (min-width: 3200px) {
  :root {
    --padding-large: 60px;
    --padding-medium: 32px;
    --padding-small: 20px;

    --font-large: 36px;
    --font-medium: 30px;
    --font-small: 24px;
  }
}

@font-face {
  font-family: "noto_sans_jp";
  src: url(./assets/fonts/noto_sans_jp/NotoSansJP-Thin.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "noto_sans_jp";
  src: url(./assets/fonts/noto_sans_jp/NotoSansJP-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "noto_sans_jp";
  src: url(./assets/fonts/noto_sans_jp/NotoSansJP-Regular.ttf)
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "noto_sans_jp";
  src: url(./assets/fonts/noto_sans_jp/NotoSansJP-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "noto_sans_jp";
  src: url(./assets/fonts/noto_sans_jp/NotoSansJP-SemiBold.ttf)
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "noto_sans_jp";
  src: url(./assets/fonts/noto_sans_jp/NotoSansJP-ExtraBold.ttf)
    format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "ms_gothic";
  src: url(./assets/fonts/ms_gothic/MSGOTHIC.TTF) format("truetype");
  font-weight: normal;
  font-style: normal;
}
