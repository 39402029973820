.zoom_btn {
  position: absolute;
  bottom: 0.5vw;
  right: 0.5vw;
  border: none;
  background: none;
  cursor: pointer;
  line-height: 0;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
}

.zoom_btn img {
  object-fit: cover;
  width: 2.5vw;
  transition: transform 0.2s ease;
}

.zoom_btn:hover {
  img {
    transform: scale(1.3) !important;
  }
}
