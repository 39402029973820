@import "src/assets/styles/mixins.scss";

.header_wrapper {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  width: 100vw;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  padding: 10px 7vw;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgb(255 255 255 / 10%),
    0 8px 16px rgb(197 197 197 / 20%), 0 12px 24px rgb(193 193 193 / 30%);
  height: 3.6875vw;
  background-color: #fff;

  .left {
    display: flex;
    align-items: center;
    height: 100%;

    img {
      height: 100%;
      scale: 1.3;
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 0.625vw;
    height: 100%;

    .line {
      width: 1px;
      background-color: #1857a666;
      height: 1.875vw;
    }

    .language_btn {
      height: 100%;
      display: flex;
      align-items: center;
      border: 1px solid #1857a6;
      padding: 0 0.4vw;
      gap: 0.4vw;
      max-height: 2.4vw;
      font-size: 1vw;

      img:first-child {
        height: 1.8vw;
      }

      img:nth-last-child(1) {
        height: 0.8vw;
      }
    }

    .language_options {
      @include flexColumn;
      transition: height 0.25s;
      overflow: hidden;
      justify-content: center;

      &.closed {
        height: 0;
      }

      &.open {
        width: 12.5vw;
        padding: 0.25vw;
      }

      .language_option {
        border: none;
        background-color: unset;
        text-align: left;
        margin: 0.2vw 0;
        display: flex;
        border-radius: 8px;
        padding: 0.4vw 0.4vw;
        font-size: 1vw;
        height: 2vw;

        img {
          margin-left: auto;
          height: 100%;
        }

        &.selected,
        &:hover {
          background-color: #e9f0f7;
        }
      }
    }

    .switch_user_btn {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 0.4vw;
      max-height: 2.4vw;

      img {
        height: 2.4vw;
      }
    }

    .profile_btn {
      height: 100%;
      display: flex;
      align-items: center;
      border: 1px solid #1857a6;
      padding: 0 0.4vw;
      gap: 0.4vw;
      max-height: 2.4vw;
      font-size: 1vw;

      img:first-child {
        height: 1.8vw;
      }
      img:nth-last-child(1) {
        height: 0.8vw;
      }
    }

    .profile_popover {
      display: flex;
      flex-direction: column;
      gap: 1vw;
      align-items: center;
      padding: 0.5vw 0.5vw;
      font-size: 1vw;

      .line {
        width: 90%;
        height: 0.05vw;
        background-color: #ccc;
      }

      .user_information_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;
        font-size: 1.2vw;
        padding: 0.5vw 2.5vw;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #f5f5f5;
        }

        &:active {
          background-color: #d48806;
          transform: scale(0.98);
        }

        img {
          height: 1.8vw;
        }
      }

      .logout_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;
        font-size: 1.2vw;
        padding: 0.5vw 2.5vw;
        background-color: #faad14; /* Màu nền chính của nút */
        color: white; /* Màu chữ */
        border: none; /* Không có viền */
        border-radius: 1000px; /* Bo góc nhẹ */
        cursor: pointer; /* Con trỏ chuột */
        transition: background-color 0.3s ease; /* Hiệu ứng chuyển màu mượt mà */

        &:hover {
          background-color: #ffc53e; /* Màu nền khi hover */
        }

        &:active {
          background-color: #d48806; /* Màu nền khi nhấn nút */
          transform: scale(0.98); /* Hiệu ứng co lại nhẹ */
        }

        img {
          height: 1.8vw;
        }
      }
    }
  }
}

.custom_setting_camera_popup {
  @include flexColumn;
  width: 80vw;
  height: 90vh;
  border-radius: 20px;
  background-color: #fff;
  text-align: center;
  z-index: 999;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 0.5vw 2vw;

  .popup_title {
    font-size: 1.5vw;
    font-weight: bold;
  }

  .body {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 0.5vw;
    gap: 2vw;

    .left {
      @include flexColumn;
      height: 100%;
      flex: 3;
      font-size: 1vw;
      color: #333;
      text-align: left;

      .camera_title {
        font-weight: bold;
        margin-bottom: 10px;
      }

      .camera_option {
        border: none;
        background-color: unset;
        text-align: left;
        margin: 0.2vw 0;
        display: flex;
        border-radius: 8px;
        padding: 0.4vw 0.4vw;
        font-size: 1vw;
        height: 2vw;

        img {
          margin-left: auto;
          height: 100%;
        }

        &.selected,
        &:hover {
          background-color: #e9f0f7;
        }
      }

      .instructions {
        margin-top: 20px;

        .instruction_title {
          font-weight: bold;
          margin-bottom: 5px;
        }

        ol {
          margin: 0;
          padding-left: 20px;
        }

        li {
          margin-bottom: 10px;
        }

        ul {
          margin: 0;
          padding-left: 20px;
        }

        strong {
          font-weight: bold;
        }
      }
    }

    .right {
      @include flexColumn;
      justify-content: center;
      align-items: center;
      height: 100%;
      flex: 7;

      .camera_container {
        flex: 1;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;

        .loadingOverlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(194, 194, 194, 0.7);
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          z-index: 1; /* Ensure it’s on top of the video */
        }

        video {
          width: auto;
          height: 100%;
          object-fit: cover;
        }

        .overlayCanvas {
          position: absolute;
          top: 50%;
          left: 50%;
          pointer-events: none;
          translate: -50% -50%;
        }
      }

      .flip_btn {
        margin-top: 0.5vw;
        padding: 0.6vw 1.2vw;
        border: none;
        border-radius: 0.5vw;
        font-size: 1vw;
        cursor: pointer;
        color: #fff;
        transition: background-color 0.3s, box-shadow 0.2s;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
      }

      .flip_btn {
        background-color: #007bff;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }

  .close_btn {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: 1vw;
    top: 1vw;
    background: unset;
    border: none;
    font-size: 1.5vw;
    z-index: 1000;
  }
}

@media (min-width: 2560px) {
  .header_wrapper {
    padding: 6px 7vw;

    .left {
      img {
        scale: 1;
      }
    }
  }
}

@media (min-width: 3200px) {
  .header_wrapper {
    padding: 10px 7vw;

    .left {
      display: flex;
      align-items: center;
      height: 100%;

      img {
        height: 100%;
      }
    }
  }
}
